body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

/* these are for drawing the grid */
.square-bottom {
  border-top: 7px solid white;
}

.square-left {
  border-left: 7px solid white;
}

/* the actual square */
.square {
  background:url(/assets/bg.png);
  float: left;
  height: 136px;
  width: 136px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px 0 0 0;
  font-family: 'Acme', sans-serif;
}

/* the square's identifier, based on the defined sequence */
.squareId {
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
  height: 10%;
  line-height: 10px;
  color: yellow;
}

.X {
  color: blue
}

.O {
  color: red;
}

.squareSelection {
  text-align: center;
  font-size: 126px;
  font-weight: bold;
  height: 90%;
  line-height: 126px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game-board {
  background-color: #332233;
  padding: 5px;
  width: calc(140 * 3px);
}

.playersTurn {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 1em;
  /* border: 2px solid blue; */
  /* padding: 5px 15px; */
  /* background-color: black; */
  /* color: white; */
}

.game-options {
  padding-left: 10px;
}

.instructions {
  /* same as the board */
  width: calc(134 * 3px);
  margin-top: 2em;
}

.mapMove {
  background-color: black;
  color: white;
  margin: 2px 0;
  float: left;
  padding: 2px 0;
  width: 33.33%;
}

.xPlay {
  float: left;
  background-color: blue;
  color: white;
  margin: 2px 0;
  padding: 2px 0;
  width: 33.33%;
}

.oPlay {
  float: left;
  background-color: red;
  color: white;
  margin: 2px 0;
  padding: 2px 0;
  width: 33.33%;
}

.mapHeader {
  font-weight: bold;
  border: 1px solid black;
  background-color: black;
  color: white;
}

.mapHeader:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  margin: 2px 0;
  float: left;
  padding: 2px 0;
  width: 33.33%;
}