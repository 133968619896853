/* Generated at https://csswarp.eleqtriq.com */

#warped {
  position: relative;
  display: block;
  width: 197px;
  height: 189px;
}

#warped > div > span[class^="w"]:nth-of-type(n + 0) {
  display: block;
  position: absolute;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

#warped span {
  font-family: "ABeeZee";
  font-size: 38px;
  font-weight: regular;
  font-style: normal;
  line-height: 0.65;
  white-space: pre;
  overflow: visible;
  padding: 0px;
}

#warped .w0 {
  -webkit-transform: rotate(-0.02rad);
  transform: rotate(-0.02rad);
  width: 19px;
  height: 24px;
  left: 83.3px;
  top: 4.01px;
}

#warped .w1 {
  -webkit-transform: rotate(0.33rad);
  transform: rotate(0.33rad);
  width: 13px;
  height: 24px;
  left: 106.94px;
  top: 7.23px;
}

#warped .w2 {
  -webkit-transform: rotate(0.68rad);
  transform: rotate(0.68rad);
  width: 19px;
  height: 24px;
  left: 122.23px;
  top: 17.35px;
}

#warped .w3 {
  -webkit-transform: rotate(1.03rad);
  transform: rotate(1.03rad);
  width: 13px;
  height: 24px;
  left: 138.94px;
  top: 33.11px;
}

#warped .w4 {
  -webkit-transform: rotate(1.38rad);
  transform: rotate(1.38rad);
  width: 19px;
  height: 24px;
  left: 143.41px;
  top: 52.62px;
}

#warped .w5 {
  -webkit-transform: rotate(1.73rad);
  transform: rotate(1.73rad);
  width: 13px;
  height: 24px;
  left: 146.73px;
  top: 73.51px;
}

#warped .w6 {
  -webkit-transform: rotate(2.08rad);
  transform: rotate(2.08rad);
  width: 19px;
  height: 24px;
  left: 136.88px;
  top: 93.25px;
}

#warped .w7 {
  -webkit-transform: rotate(2.43rad);
  transform: rotate(2.43rad);
  width: 13px;
  height: 24px;
  left: 126.67px;
  top: 109.44px;
}

#warped .w8 {
  -webkit-transform: rotate(2.78rad);
  transform: rotate(2.78rad);
  width: 19px;
  height: 24px;
  left: 105.71px;
  top: 120.12px;
}

#warped .w9 {
  -webkit-transform: rotate(3.13rad);
  transform: rotate(3.13rad);
  width: 13px;
  height: 24px;
  left: 88.18px;
  top: 123.99px;
}

#warped .w10 {
  -webkit-transform: rotate(3.48rad);
  transform: rotate(3.48rad);
  width: 19px;
  height: 24px;
  left: 64.57px;
  top: 120.59px;
}

#warped .w11 {
  -webkit-transform: rotate(3.83rad);
  transform: rotate(3.83rad);
  width: 13px;
  height: 24px;
  left: 49.37px;
  top: 110.33px;
}

#warped .w12 {
  -webkit-transform: rotate(4.18rad);
  transform: rotate(4.18rad);
  width: 19px;
  height: 24px;
  left: 32.8px;
  top: 94.44px;
}

#warped .w13 {
  -webkit-transform: rotate(4.53rad);
  transform: rotate(4.53rad);
  width: 13px;
  height: 24px;
  left: 28.49px;
  top: 74.87px;
}

#warped .w14 {
  -webkit-transform: rotate(4.88rad);
  transform: rotate(4.88rad);
  width: 19px;
  height: 24px;
  left: 25.34px;
  top: 53.98px;
}

#warped .w15 {
  -webkit-transform: rotate(5.23rad);
  transform: rotate(5.23rad);
  width: 13px;
  height: 24px;
  left: 35.36px;
  top: 34.3px;
}

#warped .w16 {
  -webkit-transform: rotate(5.58rad);
  transform: rotate(5.58rad);
  width: 19px;
  height: 24px;
  left: 45.7px;
  top: 18.22px;
}

#warped .w17 {
  -webkit-transform: rotate(5.93rad);
  transform: rotate(5.93rad);
  width: 13px;
  height: 24px;
  left: 66.75px;
  top: 7.7px;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

/* these are for drawing the grid */
.square-bottom {
  border-top: 7px solid white;
}

.square-left {
  border-left: 7px solid white;
}

/* the actual square */
.square {
  background:url(/assets/bg.png);
  float: left;
  height: 136px;
  width: 136px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 2px 0 0 0;
  font-family: 'Acme', sans-serif;
}

/* the square's identifier, based on the defined sequence */
.squareId {
  text-align: left;
  padding-left: 5px;
  font-size: 12px;
  height: 10%;
  line-height: 10px;
  color: yellow;
}

.X {
  color: blue
}

.O {
  color: red;
}

.squareSelection {
  text-align: center;
  font-size: 126px;
  font-weight: bold;
  height: 90%;
  line-height: 126px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game-board {
  background-color: #332233;
  padding: 5px;
  width: calc(140 * 3px);
}

.playersTurn {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  margin-top: 1em;
  /* border: 2px solid blue; */
  /* padding: 5px 15px; */
  /* background-color: black; */
  /* color: white; */
}

.game-options {
  padding-left: 10px;
}

.instructions {
  /* same as the board */
  width: calc(134 * 3px);
  margin-top: 2em;
}

.mapMove {
  background-color: black;
  color: white;
  margin: 2px 0;
  float: left;
  padding: 2px 0;
  width: 33.33%;
}

.xPlay {
  float: left;
  background-color: blue;
  color: white;
  margin: 2px 0;
  padding: 2px 0;
  width: 33.33%;
}

.oPlay {
  float: left;
  background-color: red;
  color: white;
  margin: 2px 0;
  padding: 2px 0;
  width: 33.33%;
}

.mapHeader {
  font-weight: bold;
  border: 1px solid black;
  background-color: black;
  color: white;
}

.mapHeader:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  margin: 2px 0;
  float: left;
  padding: 2px 0;
  width: 33.33%;
}
